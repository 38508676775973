import React, { Component } from 'react';
import Web3 from 'web3';
import './ApiComponent.css';

class ApiComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        first_buy: 0,
        last_sell: 0,
        last_load: 0,
        last_mail_sent: 0
      };
      
      //const web3 = new Web3(Web3.givenProvider || "http://localhost:8545");
    }

    componentDidMount() {
        this.getApiData();
        try {
          setInterval(async () => {
            this.getApiData()
          }, 10000);
        } catch(e) {
          console.log(e);
        }
        //this.keys[this.state.first_buy].scrollIntoView({block: 'end', behavior: 'smooth'});
    }

    update_last_time(){
      var today = new Date();
      this.setState({ last_load: (today.getHours() < 10 ? '0':'') + today.getHours() + ":" + (today.getMinutes() < 10 ? '0':'') + today.getMinutes() + ":" + (today.getSeconds() < 10 ? '0':'') + today.getSeconds()});
    }

    fromwei(val){
      return Math.round((val/1000000000000000000)*10000)/ 10000
      //return this.web3.utils.fromWei(val)
    }

    getApiData(){
        
        fetch("https://service.singularx.com/v1/order/pair/0xa017ac5fac5941f95010b12570b812c974469c2c/0x0000000000000000000000000000000000000000?0")
            .then(res => res.json())
            .then(
            (result) => {
                var result_arr = result.data.sort(this.compare)
                var first_buy = 0, last_sell = 0, first_buy_id = 0, last_sell_id = 0
                for (var i = 0; i < result_arr.length; i++){
                  if (/\w+_buy/.test(result_arr[i].id) && first_buy === 0){
                    first_buy = result_arr[i].id;
                    first_buy_id = i;
                  }
                  else {
                    last_sell = result_arr[i].id;
                    last_sell_id = i;
                  }
                  if (result_arr[i].order.tokenGet === "0x0000000000000000000000000000000000000000") {
                      result_arr[i].order.ETH = this.fromwei(result_arr[i].order.amountGet);
                      result_arr[i].order.XES = this.fromwei(result_arr[i].order.amountGive);
                  }
                  else {
                    result_arr[i].order.ETH = this.fromwei(result_arr[i].order.amountGive);
                      result_arr[i].order.XES = this.fromwei(result_arr[i].order.amountGet);
                  }
                }
                // Check if Eichhof is still King?
                if( "0x9de19384ba57e4553568befd7db1aaf473186859" === result_arr[first_buy_id].order.user && 
                    "0x9de19384ba57e4553568befd7db1aaf473186859" === result_arr[last_sell_id].order.user
                ) {
                  console.log("OK - bisch CHEF")
                }
                else {
                  console.log("HALLO !  - bisch NÜM CHEF")
                  var addontext = ""
                  if ("0x9de19384ba57e4553568befd7db1aaf473186859" !== result_arr[first_buy_id].order.user){
                    addontext ="no longer best buy"
                  }else {
                    if ("0x9de19384ba57e4553568befd7db1aaf473186859" !== result_arr[last_sell_id].order.user){
                      addontext ="no longer best sell => new user to best sell: " + result_arr[last_sell_id].order.user
                    }
                    else {
                      addontext ="i dont know the problem"
                    }
                  }
                  if(this.state.last_mail_sent == 0){
                    fetch("https://react.eichhorn.ch/mailer.php?mailalert=alert&alert=" + addontext)
                      .then(
                      (result) => {
                        this.setState({
                          last_mail_sent: this.state.last_mail_sent + 1
                        });
                      },
                      // Note: it's important to handle errors here
                      // instead of a catch() block so that we don't swallow
                      // exceptions from actual bugs in components.
                      (error) => {
                          this.setState({
                          isLoaded: true,
                          error
                          });
                      }
                      )
                    }
                    else {
                      if(this.state.last_mail_sent >= 30){
                        this.setState({
                          last_mail_sent: 0
                        });
                      }
                      else{
                        this.setState({
                          last_mail_sent: this.state.last_mail_sent + 1
                        });
                      }
                    }
                  }
                
                this.setState({
                  isLoaded: true,
                  result: result_arr,
                  first_buy: first_buy,
                  last_sell: last_sell
                });
                
                this.update_last_time()
                
                this.refs[this.state.first_buy].scrollIntoView({block: 'start', behavior: 'smooth'});

            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                this.setState({
                isLoaded: true,
                error
                });
            }
            )
    }

    GetInfo(user){
      const web3 = new Web3(Web3.givenProvider || "http://localhost:8545");
      web3.eth.getGasPrice().then( res => console.log(web3.utils.fromWei(res, 'gwei')));
      console.log("info:" + user + "GASPRICE: ");
    }
    
    compare( a, b ) {
      if ( a.price < b.price ){
        return 1;
      }
      if ( a.price > b.price ){
        return -1;
      }
      return 0;
    }  
    
    render() {
      const { error, isLoaded, result, token, first_buy, last_load } = this.state;
      
      if (error) {
        return <div className="ApiComponent">Error: {error.message}<br />token: {token}</div>;
      } else if (!isLoaded) {
        return <div className="ApiComponent">Loading...</div>;
      } else {
        return (
            <div className="ApiComponent">
                <h3>Singular Watcher</h3>
                <p>Last Load: {last_load}</p>
                <div className="ApiComponentTableWrapper">
                  <table className="ApiComponentTable">
                    <thead>
                      <tr>
                        <th>Buy/Sell</th>
                        <th>User</th>
                        <th>AmountGet</th>
                        <th>AmountGive</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>        
                      {result.map((data) => (
                        <tr  key={data.id} ref={data.id === first_buy ? first_buy : data.id} className={[
                          data.order.user === "0x9de19384ba57e4553568befd7db1aaf473186859" ? 'green' : '', 
                          data.id === first_buy ? 'top_border' : ''
                          ].join(' ')}
                          onClick={ this.GetInfo.bind(this, data.order.user)}
                        > 
                          <td>{/\w+_buy/.test(data.id) ? 'Buy': 'Sell' }</td>
                          <td>{data.order.user}</td>
                          <td>{data.order.XES }</td>
                          <td>{data.order.ETH }</td>
                          {/*<td>{web3.utils.fromWei((data.order.amountGet > data.order.amountGive ? data.order.amountGet : data.order.amountGive))}</td>
                          <td>{web3.utils.fromWei((data.order.amountGet < data.order.amountGive ? data.order.amountGet : data.order.amountGive))}</td>
                        */}<td>{Number.parseFloat(data.price).toFixed(7)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
            </div>
        );
      }
    }
  }

  export default ApiComponent;