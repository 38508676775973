import React, { Component } from 'react';
import ApiComponent from './ApiComponent';
import './ApiComponent.css';

class ApiContainer extends Component {
    
    render() {
        return (
            <ApiComponent />
        );
    }
  }

  export default ApiContainer;